/* You can add global styles to this file, and also import other style files */
@use "sass:color";
@import "~jsoneditor/dist/jsoneditor.min.css";
@import "variables.scss";

html {
  font-size: 14px;
  font-family: "Open Sans", "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
.p-component {
  font-family: "Open Sans", "Roboto", sans-serif;
  color: $brand;
}

h1 {
  font-style: normal;
  font-weight: 600;
}

////////// PRIMENG CUSTOMIZATIONS //////////

.p-error,
.delete-menu .pi.pi-trash,
.delete-menu .p-menuitem-link:not(.p-disabled):hover .pi.pi-trash {
  color: $primary-red;
}

.p-success {
  color: $accent-green;
}

.p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: $primary-red;
}

.p-button {
  background: $brand;
  color: $primary-white;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus {
  border-color: $brand;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight,
.p-button.p-button-text,
.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:enabled:active {
  color: $brand;
  background: rgba($brand, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: rgba($brand, 0.87);
  // background-color: rgba($accent-green, 0.04);
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:enabled:active,
.p-button.p-button-text:enabled:focus {
  background: rgba($brand, 0.04);
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px $brand, inset 0 0 0 1px $brand,
    inset 0 0 0 1px $brand, inset 0 0 0 1px $brand;
}

.p-button:enabled:hover,
.p-buttonset > .p-button:enabled:hover,
.p-splitbutton > .p-button:enabled:hover {
  background: rgba($brand, 0.92);
}

.p-button:enabled:active,
.p-buttonset > .p-button:enabled:active,
.p-splitbutton > .p-button:enabled:active {
  background: rgba($brand, 0.68);
}

.p-button:enabled:focus,
.p-buttonset > .p-button:enabled:focus,
.p-splitbutton > .p-button:enabled:focus {
  background: rgba($brand, 0.76);
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  background: $accent-gray-dark;
}

.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: rgba($accent-gray-dark, 0.92);
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: rgba($accent-gray-dark, 0.68);
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  background: rgba($accent-gray-dark, 0.76);
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  background: $accent-blue;
}

.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: rgba($accent-blue, 0.92);
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: rgba($accent-blue, 0.68);
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  background: rgba($accent-blue, 0.76);
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  background: $accent-green;
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: rgba($accent-green, 0.92);
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: rgba($accent-green, 0.68);
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  background: rgba($accent-green, 0.76);
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  background: $accent-red;
}

.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: rgba($accent-red, 0.92);
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: rgba($accent-red, 0.68);
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  background: rgba($accent-red, 0.76);
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  background: $accent-orange;
  color: #ffffff;
}

.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: rgba($accent-orange, 0.92);
  color: #ffffff;
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: rgba($accent-orange, 0.68);
  color: #ffffff;
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  background: rgba($accent-orange, 0.76);
  color: #ffffff;
}

.p-button:disabled {
  background-color: #dee0e280 !important;
  color: $brand !important;
  opacity: 1;
}
.p-button:disabled.p-button-text {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
}
.p-button:disabled.p-button-outlined {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.p-dialog,
.p-dynamicdialog {
  border-radius: 4px;
}

.p-dialog-content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

////////// PICKLIST //////////

.ruleset-picklist {
  .p-component.p-picklist {
    height: 100%;
  }

  .p-picklist-list-wrapper {
    min-width: 300px;
    height: calc(100vh - 350px);
    min-height: 400px;
    max-height: calc(100vh - 350px);
    text-overflow: ellipsis;
  }

  .p-picklist-header {
    background: $brand;
    color: $primary-white;
  }

  .p-picklist-list {
    height: calc(100% - 97px);
    min-height: calc(100% - 97px);
    background: $accent-gray-mid;
  }

  .p-picklist-header,
  .p-picklist-filter-container,
  .p-picklist-list {
    border: 1px solid $brand;
  }

  .p-picklist-list,
  .p-picklist-filter-container {
    border-top: none;
  }

  .p-picklist-list .p-picklist-item {
    background: $primary-white;
  }

  .p-picklist-list:not(.cdk-drop-list-dragging)
    .p-picklist-item:not(.p-highlight):hover {
    background: color.scale($accent-blue, $lightness: 80%);
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight,
  .p-picklist-list .p-picklist-item:active,
  .p-picklist-list .p-picklist-item:focus {
    background: color.scale($accent-blue, $lightness: 60%);
    .p-button-info.p-button-icon-only {
      color: $brand;
    }
  }

  .p-picklist-empty-message,
  .no-rules-available-message,
  .no-rules-in-ruleset-message {
    height: 100%;
    color: $brand;
  }

  .no-rules-available-message p,
  .no-rules-in-ruleset-message p {
    color: $brand;
  }
}

/////////// TOOLBAR ///////////

.p-component.p-toolbar.toolbar {
  border: thin solid $brand;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $brand;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 7px;
  padding-right: 7px;
  flex-wrap: nowrap;
  min-width: 410px;
}

#validation-error-btn {
  .p-button-label {
    white-space: nowrap;
  }

  .p-button-icon.pi-exclamation-circle {
    font-weight: 500;
  }
}

.p-component.p-toolbar.toolbar.collapsed {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #validation-error-btn button {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }
}

.p-component.p-toolbar.toolbar.collapse-validation:not(.collapsed) {
  .collapsible-text {
    display: none;
  }
}

.p-component.p-toolbar.toolbar.expanded {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .header-text.p-toolbar-group-left {
    max-width: 40%;
    padding-right: 1rem;
  }

  .toolbar-button-container.p-toolbar-group-right {
    padding-right: 1rem;
  }
}

/////////// OVERLAY PANEL //////////

.p-overlaypanel.p-component {
  max-width: 50vw;

  .p-overlaypanel-close {
    background: $accent-red;
  }

  .p-overlaypanel-close:enabled:hover {
    background: rgba($accent-red, 0.92);
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: #f0f0f0;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    .column-title,
    .mark-resolved-btn-title {
      display: none;
    }
  }

  @media screen and (max-width: 1054px) {
    max-width: 60vw;
    .p-datatable {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        display: grid;
        grid-template-areas:
          "code button"
          "description button"
          "solution button";
        grid-template-columns: 1fr 6.5rem;

        & > td {
          display: flex;
          width: 100%;
          border: 0 none;

          .column-title {
            padding: 0.4rem;
            min-width: 25%;
            display: inline-block;
            margin: -0.4em 1em -0.4em -0.4rem;
            font-weight: bold;
          }

          &.code-cell {
            grid-area: code;
          }

          &.description-cell {
            grid-area: description;
          }

          &.solution-cell {
            grid-area: solution;
            border-bottom: 1px solid var(--surface-d);
          }

          &.button-cell {
            grid-area: button;
            border-bottom: 1px solid var(--surface-d);

            .column-title {
              text-align: center;
              margin: 0;
              max-width: 5rem;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }
}

////////// JSON EDITOR CUSTOMIZATIONS //////////

.jsoneditor-menu {
  width: 100%;
  height: 35px;
  padding: 2px;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
  background-color: $brand;
  border-bottom: 1px solid $brand;
}
.jsoneditor {
  border: thin solid $brand;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.json-error .jsoneditor,
.json-error .p-toolbar {
  border-color: $primary-red;
  border-width: 2px;
}

.jsoneditor-menu,
.jsoneditor-mode-tree {
  min-width: 410px;
}

div.jsoneditor-tree {
  max-height: calc(100vh - 350px);
}

.jsoneditor-statusbar,
div.jsoneditor-tree {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

div.jsoneditor-modes {
  font-family: "Roboto", sans-serif;
}

div.jsoneditor-modal,
div.jsoneditor-mdoal .pico-modal-contents,
div.jsoneditor-mdoal .pico-modal-header {
  border-radius: 4px !important;
}

.jsoneditor-modal .pico-modal-header,
.jsoneditor-modal
  .jsoneditor-button-group.jsoneditor-button-group-value-asc
  input.jsoneditor-button-asc,
.jsoneditor-modal
  .jsoneditor-button-group.jsoneditor-button-group-value-desc
  input.jsoneditor-button-desc {
  background: $brand;
  background-color: $brand;
  border-color: $brand;
}

.jsoneditor-modal select {
  border-radius: 4px;
}

.jsoneditor-modal select:focus,
.jsoneditor-modal select:active {
  border: 2px solid $brand;
  outline: none;
}

////////// DIALOGS //////////

.rule-viewer-dialog {
  .p-dialog-content {
    .json-viewer-wrapper {
      max-width: 100%;
    }
  }

  .p-dialog-title {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.p-dialog {
  .p-dialog-header {
    .p-dialog-title {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}

.template-selector-dialog,
.rule-viewer-dialog,
.audit-log-dialog {
  .p-dialog-header {
    background: $brand;
    color: #ffffff;
  }

  .p-dialog-header-close-icon {
    color: #ffffff;
  }
}

.audit-log-dialog {
  .audit-log-panel {
    .p-panel-header {
      background: $accent-gray-mid;

      .p-panel-title {
        font-weight: 700;
      }

      .audit-log-panel-header-date {
        font-weight: 400;
      }
    }
  }
}

.rule-viewer-dialog.p-dialog {
  max-width: 60vw;
}

///////// OTHER //////////

.main-btn.p-button {
  width: fit-content;
}

.main-btn.toolbar-btn {
  margin-left: 1em;
  min-width: fit-content;
}

.light-text {
  color: $accent-gray-dark;
}

.p-divider-vertical {
  height: 80%;
}

.p-divider.p-divider-vertical {
  height: 80%;
  margin-right: 0;
}

.p-listbox-item:hover,
.p-listbox-item,
.p-listbox-list:hover,
.p-listbox-list,
.p-listbox:hover {
  cursor: default !important;
}

#template-selector .p-listbox.p-component {
  height: 100%;
}

.p-blockui {
  z-index: 900;
}

.validation-spinner {
  .p-progress-spinner {
    width: 20vh;
    height: 20vh;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-10vh, -10vh);
    z-index: 1001;
  }
}
